import { EmptyMap } from 'lib/array/arrayUtils'
import { createSelector } from 'reselect'
import getAllBreakdownViews from './getAllBreakdownViews'

const getAllItemsSummaryTitles = createSelector(
  (state: App.State) => getAllBreakdownViews(state),
  (allBreakdownViews): Map<string, Array<string>> => {
    if (!allBreakdownViews.hasRequiredData) return EmptyMap
    const itemsTitles = new Map<string, Array<string>>()
    allBreakdownViews.data.forEach((breakdownView) => {
      breakdownView.items.forEach((itemBreakdownView) => {
        if (itemBreakdownView.itemId) {
          if (!itemsTitles.has(itemBreakdownView.itemId)) {
            itemsTitles.set(itemBreakdownView.itemId, [])
          }

          const titles = itemsTitles.get(itemBreakdownView.itemId)!
          switch (itemBreakdownView.itemType) {
            case 'insurance':
            case 'bookingProtection':
            case 'villa':
            case 'tourV2Experience':
              titles.push(...[breakdownView.title].filter((i): i is string => !!i))
              break
            case 'experience':
              // experiences work at the ticket item leve which is faked on the view
              // so we process them differently
              itemBreakdownView.itemView?.ticketViews.forEach(ticketView => {
                itemsTitles.set(ticketView.item.itemId, [ticketView.ticket.name])
              })
              break
            default:
              titles.push(...[itemBreakdownView.title, breakdownView.description].filter((i): i is string => !!i))
          }
        }
      })
    })

    if (!itemsTitles.size) return EmptyMap
    return itemsTitles
  },
)

export default getAllItemsSummaryTitles
